/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
.arrowLink {
  font-size: 0;
  padding: 9px;
  color: #666;
  flex-shrink: 0;
}

.arrow {
  width: 26px;
  height: 26px;
}

.arrowFiller {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
}