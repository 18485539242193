.container {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.swatchCircle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f3f3f3;
  margin-right: 9px;
  border: 1px solid #f3f3f3;
}

.swatchesCount {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #222;
  margin-left: 9px;
}
@media (max-width: 568px) {
  .swatchesCount {
    margin-left: 0;
  }
}

.link {
  text-decoration: none;
}
.link:hover {
  color: currentColor;
}