.retail.hasNet, .retail.hasSale, .retail.hasPrivateOffer {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #666;
}

.hasNet .price, .hasSale .price, .unpurchasable .price, .hasPrivateOffer .price {
  text-decoration: line-through;
}

.blur {
  filter: blur(4px);
  user-select: none;
}

.net.hasPrivateOffer {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #666;
}

.label {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  margin-left: 5px;
}
.label.showLabelOnNewLine {
  display: block;
  margin-left: 0;
}
.label:nth-child(n+3):last-child:not(:empty)::before {
  content: "|";
  margin-right: 5px;
}
.label.hideSeparator::before {
  display: none;
}

.expiration {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  display: block;
  color: #222;
}

.discount {
  margin-left: 9px;
}
.hasNet .discount {
  color: #0d7d77;
}
.hasSale .discount {
  color: #950808;
}
.hasPrivateOffer .discount {
  color: #950808;
}

.inline {
  display: inline;
}

.auctionSiderailRedesignContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auctionSiderailRedesignLabel {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.auctionSiderailRedesignAmount {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 300;
  text-decoration: line-through;
}