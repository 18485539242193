.retail.hasNet, .retail.hasSale, .retail.hasPrivateOffer {
  color: #666;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.hasNet .price, .hasSale .price, .unpurchasable .price, .hasPrivateOffer .price {
  text-decoration: line-through;
}

.blur {
  filter: blur(4px);
  -webkit-user-select: none;
  user-select: none;
}

.net.hasPrivateOffer {
  color: #666;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.label {
  margin-left: 5px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.label.showLabelOnNewLine {
  margin-left: 0;
  display: block;
}

.label:nth-child(n+3):last-child:not(:empty):before {
  content: "|";
  margin-right: 5px;
}

.label.hideSeparator:before {
  display: none;
}

.expiration {
  color: #222;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.discount {
  margin-left: 9px;
}

.hasNet .discount {
  color: #0d7d77;
}

.hasSale .discount, .hasPrivateOffer .discount {
  color: #950808;
}

.inline {
  display: inline;
}

.auctionSiderailRedesignContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.auctionSiderailRedesignLabel {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.auctionSiderailRedesignAmount {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 300;
  text-decoration: line-through;
}
