/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
.carouselWrapper {
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  width: 100%;
}

.carousel {
  display: flex;
  flex-grow: 1;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.carousel.noneHorizontal {
  margin: 0 calc(-1px / 2);
}
.carousel.noneVertical {
  margin: calc(-1px / 2) 0;
}
.carousel.xSmallHorizontal {
  margin: 0 calc(-9px / 2);
}
.carousel.xSmallVertical {
  margin: calc(-9px / 2) 0;
}
.carousel.smallHorizontal {
  margin: 0 calc(-18px / 2);
}
.carousel.smallVertical {
  margin: calc(-18px / 2) 0;
}
.carousel.mediumHorizontal {
  margin: 0 calc(-27px / 2);
}
.carousel.mediumVertical {
  margin: calc(-27px / 2) 0;
}
.carousel.largeHorizontal {
  margin: 0 calc(-36px / 2);
}
.carousel.largeVertical {
  margin: calc(-36px / 2) 0;
}
.carousel.noneHorizontalScrollPadding {
  scroll-padding: 0;
  padding: 0 0;
}
.carousel.noneVerticalScrollPadding {
  scroll-padding: 0;
  padding: 0 0;
}
.carousel.smallHorizontalScrollPadding {
  scroll-padding: 18px;
  padding: 0 18px;
}
.carousel.smallVerticalScrollPadding {
  scroll-padding: 18px;
  padding: 18px 0;
}
.carousel.largeHorizontalScrollPadding {
  scroll-padding: 36px;
  padding: 0 36px;
}
.carousel.largeVerticalScrollPadding {
  scroll-padding: 36px;
  padding: 36px 0;
}
.carousel.slide {
  overflow: auto;
}
.carousel.none, .carousel.disableScroll {
  overflow: hidden;
}
.carousel.horizontal {
  flex-direction: row;
  scroll-snap-type: x mandatory;
}
.carousel.vertical {
  flex-direction: column;
  scroll-snap-type: y mandatory;
}
.carousel::-webkit-scrollbar {
  display: none;
}