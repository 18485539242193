.container {
  box-sizing: border-box;
  border: 1px solid #0000;
  width: 100%;
  max-width: 346px;
  position: relative;
}

.container.activeHover, .container:hover {
  color: currentColor;
  border-color: #ddd;
  transition: border-color .5s;
}

.container:hover .footer {
  opacity: 1;
  transition: opacity .5s;
}

.container.disableBorder {
  border-color: #0000 !important;
}

.container.alignCenter {
  margin: auto;
}

@media (hover: none) {
  .container:hover {
    border-color: #0000;
  }
}

.footer {
  opacity: 0;
  justify-content: space-between;
  max-width: 310px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  display: flex;
}

.footer.activeHover {
  opacity: 1;
  transition: opacity .5s;
}

.content {
  box-sizing: border-box;
  max-width: 310px;
  margin: 0 18px;
  padding: 27px 0 18px;
}

@media (width <= 768px) {
  .content {
    margin: 18px 9px 0;
    padding: 0;
  }

  .content.lightBoxLayout {
    margin: 0;
    padding: 0;
  }
}

.disableContentMargin {
  margin: 0;
}

.disableContentPadding {
  padding: 0;
}

.info {
  margin-top: 12px;
}

.infoLightBox {
  margin: 12px -18px 0;
}

@media (width <= 768px) {
  .infoLightBox {
    margin: 0;
    padding: 18px 0 0;
  }
}

.pricePlaceHolder {
  min-height: 21px;
  margin: 4px 0 12px;
}

.titleWrapper {
  justify-content: space-between;
  display: flex;
}

.tileLink {
  text-decoration: none;
}

.tileLink:hover {
  color: currentColor;
}

.titleLink {
  overflow: hidden;
}

.title {
  flex: 1;
}

@supports (-webkit-line-clamp: 2) {
  .title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.titleHeader {
  margin: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.creator {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.artTitle {
  white-space: nowrap;
  flex-wrap: nowrap;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.artTitleNoCreator {
  margin-top: 21px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.measurementsWrapper {
  color: #888;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.isProminentTile {
  max-width: none;
}

.productContainer {
  position: relative;
}

.quickViewLightBox {
  position: absolute;
  bottom: 12px;
  right: 0;
}

@media (width <= 768px) {
  .quickViewLightBox {
    margin-bottom: -9px;
    right: 12px;
  }
}

.priceBidsWrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}
