.link {
  fill: #ddd;
  color: #ddd;
}
@media (max-width: 568px) {
  .link {
    position: absolute;
    width: 11px;
    height: 11px;
    right: 0;
    bottom: 15px;
  }
  .link.hideDivider {
    bottom: 9px;
  }
  .link.isSbTile {
    position: static;
    bottom: 0;
    margin: 5px 0 9px 9px;
  }
}
@media (min-width: 569px) {
  .link {
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 9px;
  }
  .link.isSbTile {
    margin: 5px 0 0 9px;
  }
}

.isSbTile {
  fill: #bbb;
  width: 12px;
  height: 12px;
  display: block;
  position: static;
}