.price {
  display: flex;
  flex-wrap: wrap;
}
.price.fontSmall {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
.price.fontSmall.priceBold {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
}
.price.fontMedium {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
.price.fontMedium.priceBold {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}
.price.fontLarge {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 300;
}
.price.fontLarge.priceBold {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 600;
}
.price.imageSmall {
  padding-top: 9px;
}
@media (max-width: 768px) {
  .price.imageSmall {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 300;
  }
  .price.imageSmall.priceBold {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 600;
  }
}
.price.imageLarge, .price.imageMedium {
  padding-top: 18px;
}
@media (max-width: 768px) {
  .price.imageLarge, .price.imageMedium {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 300;
    padding-top: 9px;
  }
  .price.imageLarge.priceBold, .price.imageMedium.priceBold {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 600;
  }
}
.price.imageXLarge {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  padding: 9px 0;
}
.price.imageXLarge.priceBold {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}
.price.isSbTile {
  padding-top: 4px;
  padding-bottom: 9px;
}
.price.reduceMargins {
  padding-top: 2px;
  padding-bottom: 2px;
}

.priceLabel {
  padding-right: 9px;
}
@media (max-width: 768px) {
  .priceLabel {
    padding-right: 18px;
  }
}
@media (max-width: 568px) {
  .priceLabel {
    padding-right: 4px;
  }
}

.leftPipeDivider:before, .percentageOff:before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 1px;
  margin-right: 9px;
  background: #ddd;
}
@media (max-width: 768px) {
  .leftPipeDivider:before, .percentageOff:before {
    margin-right: 5px;
  }
}

.percentageOff {
  display: flex;
  align-items: center;
}
.percentageOff.sale {
  color: #950808;
}
.percentageOff.net {
  color: #0f8d88;
}
.percentageOff.isSbTile:before {
  display: none;
}

.textTypePriceWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 9px;
}
.textTypePriceWrapper .price {
  padding-bottom: 0;
}
.textTypePriceWrapper .price:not(:last-child) {
  padding-right: 9px;
}
@media (max-width: 768px) {
  .textTypePriceWrapper .price:not(:last-child) {
    padding-right: 5px;
  }
}
.textTypePriceWrapper .leftPipeDivider, .textTypePriceWrapper .percentageOff {
  align-items: center;
}
.textTypePriceWrapper .leftPipeDivider::before, .textTypePriceWrapper .percentageOff::before {
  background: #000;
}

.priceLineThrough > .priceLabel {
  text-decoration: line-through;
}

.alert {
  color: #950808;
}

.paddleContainer {
  display: inline-block;
  width: 10px;
  margin-right: 9px;
}

.paddle {
  display: block;
  margin-top: 4px;
}