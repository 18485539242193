.wrapper {
  z-index: 10;
  display: none;
  position: absolute;
  inset: 0;
}

.wrapper:focus {
  outline: none;
}

.wrapper.isActive {
  display: block;
}

.item {
  background: #fbfbfb;
  width: 100%;
  height: 100%;
  padding: 36px 27px;
  position: relative;
}

.item.isSbTile {
  background: #fff;
  padding: 45px 18px 18px;
}

@media (width <= 768px) {
  .item {
    padding: 36px 9px 18px;
  }
}

.close {
  line-height: unset;
  cursor: pointer;
  color: #222;
  background: none;
  border: none;
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 18px;
  right: 18px;
}

.close:hover {
  color: #bbb;
}

@media (width <= 768px) {
  .close {
    top: 9px;
    right: 9px;
  }
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 300;
}

@media (width <= 768px) {
  .title {
    font-size: 14px;
    font-weight: 300;
  }
}

.paragraph {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 768px) {
  .paragraph {
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 300;
  }
}

.testContainer {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
}

.testParagraphs {
  overflow: auto;
}

.testParagraphs :last-child {
  margin-bottom: 18px;
}

.testTitle {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
  .testTitle {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

@media (width <= 768px) {
  .testTitle {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 12px;
    font-weight: 600;
  }
}

.testCreator {
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 768px) {
  .testCreator {
    font-size: 12px;
    font-weight: 300;
  }
}

.testDescription {
  margin-top: 18px;
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 768px) {
  .testDescription {
    margin-top: 9px;
    font-size: 12px;
    font-weight: 300;
  }

  .testDescription:last-child {
    margin-bottom: 9px;
  }

  .testDescription {
    margin-bottom: 18px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 12px;
    font-weight: 300;
  }
}

.testCategory {
  margin-top: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 768px) {
  .testCategory {
    font-size: 12px;
    font-weight: 300;
  }
}

.testParagraph {
  margin-top: 18px;
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 768px) {
  .testParagraph {
    margin-top: 9px;
    font-size: 12px;
    font-weight: 300;
  }

  .testParagraph:last-child {
    margin-bottom: 9px;
  }
}

.testParagraph:first-line {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

@media (width <= 768px) {
  .testParagraph:first-line {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 12px;
    font-weight: 600;
  }
}
