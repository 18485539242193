:local .small {
  --button-padding: 18px;
  --button-height: 33px;
  --button-line-height: 33px;
  font-size: 12px;
  font-weight: 300;
}
:local .medium {
  --button-padding: 18px;
  --button-height: 40px;
  --button-line-height: 40px;
  font-size: 14px;
  font-weight: 300;
}
:local .large {
  --button-padding: 36px;
  --button-height: 50px;
  --button-line-height: 50px;
  font-size: 16px;
  font-weight: 300;
}
:local .wrap,
:local .noWrap {
  height: var(--button-height);
  padding: 0 var(--button-padding);
  display: inline-block;
  line-height: var(--button-line-height);
}
:local :global(.button).wrap:not(:has(> *)) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  white-space: normal;
  word-break: normal;
}
:local .secondaryButton, :local .transparentButton {
  color: #222;
  fill: #222;
  background-color: #fff;
  border: 2px solid #222;
}
:local .secondaryButton:hover:not(.disabled), :local .transparentButton:hover:not(.disabled) {
  color: #fff;
  fill: #fff;
  background-color: #222;
}
:local .textCtaButton {
  color: #222;
  fill: #222;
  background-color: #fff;
}
:local .textCtaButton:hover:not(.disabled) {
  color: #222;
  fill: #222;
  background-color: #fff;
}
:local .transparentButton {
  background-color: transparent;
}
:local .transparentButton.disabled {
  background-color: transparent;
  color: #ddd;
  fill: #ddd;
  border: 2px solid #ddd;
}
:local .primaryAltButton {
  color: #fff;
  fill: #fff;
  background-color: #222;
}
:local .primaryAltButton:hover {
  background-color: #000;
}
:local .secondaryAltButton {
  color: #222;
  fill: #222;
  background-color: #fff;
  border: 2px solid #222;
}
:local .secondaryAltButton:hover:not(.disabled) {
  color: #fff;
  fill: #fff;
  background-color: #222;
}
:local .secondaryButton.small,
:local .secondaryAltButton.small,
:local .transparentButton.small {
  --button-line-height: 31px;
}
:local .secondaryButton.medium,
:local .secondaryAltButton.medium,
:local .transparentButton.medium {
  --button-line-height: 38px;
}
:local .secondaryButton.large,
:local .secondaryAltButton.large,
:local .transparentButton.large {
  --button-line-height: 46px;
}
:local .fullWidth {
  width: 100%;
}
:local .isDangerousActionPrimary {
  background-color: #cc0000;
  color: #fff;
  fill: #fff;
}
:local .isDangerousActionPrimary:hover:not(.disabled) {
  background-color: #cc0000;
  color: #fff;
  fill: #fff;
}
:local .isDangerousActionSecondary {
  background-color: #fff;
  color: #cc0000;
  fill: #cc0000;
  border-color: #cc0000;
}
:local .isDangerousActionSecondary:hover:not(.disabled) {
  background-color: #ddd;
  color: #cc0000;
  fill: #cc0000;
  border-color: #cc0000;
}
:local .isDangerousActionTransparent {
  background-color: transparent;
  color: #cc0000;
  fill: #cc0000;
  border-color: #cc0000;
}
:local .isDangerousActionTransparent:hover:not(.disabled) {
  background-color: #ddd;
  color: #cc0000;
  fill: #cc0000;
  border-color: #cc0000;
}