.swipeIndicator {
  color: #fff;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  background-color: #2229;
  justify-content: center;
  align-items: center;
  padding: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  transition: opacity .1s, visibility .1s;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.swipeIndicator.isVisible {
  opacity: 1;
  visibility: visible;
}

.swipeIndicatorArrow {
  height: 16px;
  margin-left: 9px;
}
