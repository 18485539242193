/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
.carouselItem {
  flex-shrink: 0;
  scroll-snap-stop: always;
  overflow: hidden;
  display: flex;
}
.carouselItem.noneHorizontal {
  padding: 0 calc(1px / 2);
}
.carouselItem.noneVertical {
  padding: calc(1px / 2) 0;
}
.carouselItem.xSmallHorizontal {
  padding: 0 calc(9px / 2);
}
.carouselItem.xSmallVertical {
  padding: calc(9px / 2) 0;
}
.carouselItem.smallHorizontal {
  padding: 0 calc(18px / 2);
}
.carouselItem.smallVertical {
  padding: calc(18px / 2) 0;
}
.carouselItem.mediumHorizontal {
  padding: 0 calc(27px / 2);
}
.carouselItem.mediumVertical {
  padding: calc(27px / 2) 0;
}
.carouselItem.largeHorizontal {
  padding: 0 calc(36px / 2);
}
.carouselItem.largeVertical {
  padding: calc(36px / 2) 0;
}

.carouselSnapItemStart {
  scroll-snap-align: start;
}

.carouselSnapItemEnd {
  scroll-snap-align: end;
}