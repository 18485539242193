.link {
  fill: #ddd;
  color: #ddd;
}

@media (width <= 568px) {
  .link {
    width: 11px;
    height: 11px;
    position: absolute;
    bottom: 15px;
    right: 0;
  }

  .link.hideDivider {
    bottom: 9px;
  }

  .link.isSbTile {
    margin: 5px 0 9px 9px;
    position: static;
    bottom: 0;
  }
}

@media (width >= 569px) {
  .link {
    width: 18px;
    height: 18px;
    margin-right: 9px;
    display: block;
  }

  .link.isSbTile {
    margin: 5px 0 0 9px;
  }
}

.isSbTile {
  fill: #bbb;
  width: 12px;
  height: 12px;
  display: block;
  position: static;
}
