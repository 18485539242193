.placeholderHidden {
  visibility: hidden;
  opacity: 0;
}

.placeholder {
  position: absolute;
  z-index: 2;
  width: 100%;
  filter: blur(10px);
}
.placeholder.imageXSmall {
  max-width: 148px;
  height: 148px;
}
@media (max-width: 1024px) {
  .placeholder.imageXSmall {
    max-width: 90px;
    height: 90px;
  }
}
@media (max-width: 768px) {
  .placeholder.imageXSmall {
    max-width: 90px;
    height: 90px;
  }
}
.placeholder.imageSmall {
  max-width: 160px;
  height: 160px;
}
@media (max-width: 1024px) {
  .placeholder.imageSmall {
    max-width: 130px;
    height: 130px;
  }
}
@media (max-width: 768px) {
  .placeholder.imageSmall {
    max-width: 122px;
    height: 122px;
  }
}
.placeholder.imageMedium {
  max-width: 220px;
  height: 220px;
}
@media (max-width: 1024px) {
  .placeholder.imageMedium {
    max-width: 180px;
    height: 180px;
  }
}
@media (max-width: 768px) {
  .placeholder.imageMedium {
    max-width: 140px;
    height: 140px;
  }
}
@media (max-width: 568px) {
  .placeholder.imageMedium {
    max-width: 130px;
    height: 130px;
  }
}
.placeholder.imageLarge {
  max-width: 360px;
  height: 360px;
}
@media (max-width: 1024px) {
  .placeholder.imageLarge {
    max-width: 180px;
    height: 180px;
  }
}
@media (max-width: 768px) {
  .placeholder.imageLarge {
    max-width: 180px;
    height: 180px;
  }
}
.placeholder.imageXLarge {
  max-width: 550px;
  height: 550px;
}
@media (max-width: 1024px) {
  .placeholder.imageXLarge {
    max-width: 450px;
    height: 450px;
  }
}
@media (max-width: 768px) {
  .placeholder.imageXLarge {
    max-width: 350px;
    height: 350px;
  }
}
.placeholder > svg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: auto;
  width: auto;
}
.placeholder.isNarrow > svg {
  height: 100%;
}