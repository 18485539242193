.wrapper {
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: none;
}
.wrapper:focus {
  outline: none;
}
.wrapper.isActive {
  display: block;
}

.item {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 36px 27px;
  background: #fbfbfb;
}
.item.isSbTile {
  background: #fff;
  padding: 45px 18px 18px 18px;
}
@media (max-width: 768px) {
  .item {
    padding: 36px 9px 18px;
  }
}

.close {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: unset;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 18px;
  width: 15px;
  height: 15px;
  color: #222;
}
.close:hover {
  color: #bbb;
}
@media (max-width: 768px) {
  .close {
    top: 9px;
    right: 9px;
  }
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.title {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .title {
    font-size: 14px;
    font-weight: 300;
  }
}

.paragraph {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .paragraph {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 9px;
  }
}

.testContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testParagraphs {
  overflow: auto;
}
.testParagraphs :last-child {
  margin-bottom: 18px;
}

.testTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
}
@supports (-webkit-line-clamp: 2) {
  .testTitle {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
  .testTitle {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 768px) {
  .testTitle {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 12px;
    font-weight: 600;
  }
}

.testCreator {
  font-size: 14px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .testCreator {
    font-size: 12px;
    font-weight: 300;
  }
}

.testDescription {
  font-size: 14px;
  font-weight: 300;
  margin-top: 18px;
}
@media (max-width: 768px) {
  .testDescription {
    font-size: 12px;
    font-weight: 300;
    margin-top: 9px;
  }
  .testDescription:last-child {
    margin-bottom: 9px;
  }
}
@media (max-width: 768px) {
  .testDescription {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 18px;
  }
}

.testCategory {
  margin-top: 0;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .testCategory {
    font-size: 12px;
    font-weight: 300;
  }
}

.testParagraph {
  font-size: 14px;
  font-weight: 300;
  margin-top: 18px;
}
@media (max-width: 768px) {
  .testParagraph {
    font-size: 12px;
    font-weight: 300;
    margin-top: 9px;
  }
  .testParagraph:last-child {
    margin-bottom: 9px;
  }
}
.testParagraph::first-line {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .testParagraph::first-line {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 12px;
    font-weight: 600;
  }
}