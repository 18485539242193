.container {
  align-items: center;
  padding-bottom: 12px;
  display: flex;
}

.swatchCircle {
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 9px;
  display: inline-block;
}

.swatchesCount {
  color: #222;
  margin-left: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 568px) {
  .swatchesCount {
    margin-left: 0;
  }
}

.link {
  text-decoration: none;
}

.link:hover {
  color: currentColor;
}
