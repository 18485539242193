/* FlatVideo Icon block */
.videoIcon {
  position: absolute;
  box-sizing: content-box;
  z-index: 10;
  width: 23px;
  transform: scale(0.8);
  color: #ddd;
  transition: opacity 0.1667s;
  opacity: 1;
  padding: 9px;
  top: -27px;
  left: -18px;
}
@media (max-width: 768px) {
  .videoIcon {
    top: -18px;
    left: -9px;
  }
}

.hideVideoIcon {
  opacity: 0;
}