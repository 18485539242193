@keyframes imageGridBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.link {
  display: block;
  position: relative;
}

.product {
  cursor: pointer;
}

.hasDivider {
  border-bottom: 1px solid #ddd;
}

.hasLightBox {
  background: #fff;
  border-color: #0000;
  margin: -27px -18px 0;
}

@media (width <= 768px) {
  .hasLightBox {
    margin: 0;
    padding: 18px;
  }
}

.imageWrapper {
  padding: 18px 18px 9px;
  position: relative;
}

.lightBoxImage {
  padding: 27px 18px;
}

.hideImagePadding {
  padding: 0;
}

.hideImageSidePadding {
  padding-left: 0;
  padding-right: 0;
}

.imageContainer, .swiperImageContainer {
  box-sizing: border-box;
  position: relative;
}

.imageContainer.imageXSmall, .imageXSmall.swiperImageContainer {
  max-width: 148px;
  height: 148px;
}

@media (width <= 1024px) {
  .imageContainer.imageXSmall, .imageXSmall.swiperImageContainer {
    max-width: 90px;
    height: 90px;
  }
}

@media (width <= 768px) {
  .imageContainer.imageXSmall, .imageXSmall.swiperImageContainer {
    max-width: 90px;
    height: 90px;
  }
}

.imageContainer.imageSmall, .imageSmall.swiperImageContainer {
  max-width: 160px;
  height: 160px;
}

@media (width <= 1024px) {
  .imageContainer.imageSmall, .imageSmall.swiperImageContainer {
    max-width: 130px;
    height: 130px;
  }
}

@media (width <= 768px) {
  .imageContainer.imageSmall, .imageSmall.swiperImageContainer {
    max-width: 122px;
    height: 122px;
  }
}

.imageContainer.imageMedium, .imageMedium.swiperImageContainer {
  max-width: 220px;
  height: 220px;
}

@media (width <= 1024px) {
  .imageContainer.imageMedium, .imageMedium.swiperImageContainer {
    max-width: 180px;
    height: 180px;
  }
}

@media (width <= 768px) {
  .imageContainer.imageMedium, .imageMedium.swiperImageContainer {
    max-width: 140px;
    height: 140px;
  }
}

@media (width <= 568px) {
  .imageContainer.imageMedium, .imageMedium.swiperImageContainer {
    max-width: 130px;
    height: 130px;
  }
}

.imageContainer.imageLarge, .imageLarge.swiperImageContainer {
  max-width: 360px;
  height: 360px;
}

@media (width <= 1024px) {
  .imageContainer.imageLarge, .imageLarge.swiperImageContainer {
    max-width: 180px;
    height: 180px;
  }
}

@media (width <= 768px) {
  .imageContainer.imageLarge, .imageLarge.swiperImageContainer {
    max-width: 180px;
    height: 180px;
  }
}

.imageContainer.imageXLarge, .imageXLarge.swiperImageContainer {
  max-width: 550px;
  height: 550px;
}

@media (width <= 1024px) {
  .imageContainer.imageXLarge, .imageXLarge.swiperImageContainer {
    max-width: 450px;
    height: 450px;
  }
}

@media (width <= 768px) {
  .imageContainer.imageXLarge, .imageXLarge.swiperImageContainer {
    max-width: 350px;
    height: 350px;
  }
}

.centerImage {
  margin: auto;
}

.centerVerticaly {
  margin: auto 0;
}

.swiperImageContainer {
  -webkit-user-select: none;
  user-select: none;
}

.image, .swiperImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.swiperImage {
  margin: auto;
  padding: 0 1px;
}

.swiperLink {
  width: 100%;
  height: 100%;
}

.carouselWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.arrows {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.arrows.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s;
}

.nextArrow {
  right: 0;
}

.prevArrow {
  left: 0;
}

.productDetailWrapper {
  margin-top: -9px;
  padding-bottom: 9px;
  display: flex;
}

.hasLightBox .productDetailWrapper {
  padding-left: 9px;
}

.productDetailWrapper.hideDivider {
  padding: 0;
}

@media (width <= 568px) {
  .productDetailWrapper {
    flex-direction: column;
    justify-content: flex-end;
    height: 54px;
    position: relative;
  }
}

@media (width >= 569px) {
  .productDetailWrapper {
    align-items: center;
  }
}

.dimensions {
  color: #888;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
}

@media (width <= 568px) {
  .dimensions {
    width: 90%;
    margin-top: 6px;
    line-height: 1.5;
  }
}
