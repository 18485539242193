.container {
  position: relative;
  width: 100%;
  max-width: 346px;
  box-sizing: border-box;
  border: 1px solid;
  border-color: transparent;
}
.container.activeHover {
  border-color: #ddd;
  transition: border-color 0.5s;
  color: currentColor;
}
.container:hover {
  border-color: #ddd;
  transition: border-color 0.5s;
  color: currentColor;
}
.container:hover .footer {
  opacity: 1;
  transition: opacity 0.5s;
}
.container.disableBorder {
  border-color: transparent !important;
}
.container.alignCenter {
  margin: auto;
}
@media (hover: none) {
  .container:hover {
    border-color: transparent;
  }
}

.footer {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  max-width: 310px;
}
.footer.activeHover {
  opacity: 1;
  transition: opacity 0.5s;
}

.content {
  box-sizing: border-box;
  max-width: 310px;
  margin: 0 18px;
  padding: 27px 0 18px 0;
}
@media (max-width: 768px) {
  .content {
    margin: 18px 9px 0 9px;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .content.lightBoxLayout {
    margin: 0;
    padding: 0;
  }
}

.disableContentMargin {
  margin: 0;
}

.disableContentPadding {
  padding: 0;
}

.info {
  margin-top: 12px;
}

.infoLightBox {
  margin: 12px -18px 0;
}
@media (max-width: 768px) {
  .infoLightBox {
    margin: 0;
    padding: 18px 0 0;
  }
}

.pricePlaceHolder {
  min-height: 21px;
  margin: 4px 0 12px 0;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.tileLink {
  text-decoration: none;
}
.tileLink:hover {
  color: currentColor;
}

.titleLink {
  overflow: hidden;
}

.title {
  flex: 1;
}
@supports (-webkit-line-clamp: 2) {
  .title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
  .title {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

.titleHeader {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.creator {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.artTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.artTitleNoCreator {
  margin-top: 21px;
}

.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.measurementsWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  color: #888;
}

.isProminentTile {
  max-width: none;
}

.productContainer {
  position: relative;
}

.quickViewLightBox {
  position: absolute;
  bottom: 12px;
  right: 0;
}
@media (max-width: 768px) {
  .quickViewLightBox {
    right: 12px;
    margin-bottom: -9px;
  }
}

.priceBidsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}