:local .small {
  --button-padding: 18px;
  --button-height: 33px;
  --button-line-height: 33px;
  font-size: 12px;
  font-weight: 300;
}

:local .medium {
  --button-padding: 18px;
  --button-height: 40px;
  --button-line-height: 40px;
  font-size: 14px;
  font-weight: 300;
}

:local .large {
  --button-padding: 36px;
  --button-height: 50px;
  --button-line-height: 50px;
  font-size: 16px;
  font-weight: 300;
}

:is(:local .wrap, :local .noWrap) {
  height: var(--button-height);
  padding: 0 var(--button-padding);
  line-height: var(--button-line-height);
  display: inline-block;
}

:local :global(.button).wrap:not(:has( > *)) {
  white-space: normal;
  word-break: normal;
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: inline-flex;
}

:is(:local .secondaryButton, :local .transparentButton) {
  color: #222;
  fill: #222;
  background-color: #fff;
  border: 2px solid #222;
}

:is(:local .secondaryButton:hover:not(.disabled), :local .transparentButton:hover:not(.disabled)) {
  color: #fff;
  fill: #fff;
  background-color: #222;
}

:local .textCtaButton {
  color: #222;
  fill: #222;
  background-color: #fff;
}

:local .textCtaButton:hover:not(.disabled) {
  color: #222;
  fill: #222;
  background-color: #fff;
}

:local .transparentButton {
  background-color: #0000;
}

:local .transparentButton.disabled {
  color: #ddd;
  fill: #ddd;
  background-color: #0000;
  border: 2px solid #ddd;
}

:local .primaryAltButton {
  color: #fff;
  fill: #fff;
  background-color: #222;
}

:local .primaryAltButton:hover {
  background-color: #000;
}

:local .secondaryAltButton {
  color: #222;
  fill: #222;
  background-color: #fff;
  border: 2px solid #222;
}

:local .secondaryAltButton:hover:not(.disabled) {
  color: #fff;
  fill: #fff;
  background-color: #222;
}

:is(:local .secondaryButton.small, :local .secondaryAltButton.small, :local .transparentButton.small) {
  --button-line-height: 31px;
}

:is(:local .secondaryButton.medium, :local .secondaryAltButton.medium, :local .transparentButton.medium) {
  --button-line-height: 38px;
}

:is(:local .secondaryButton.large, :local .secondaryAltButton.large, :local .transparentButton.large) {
  --button-line-height: 46px;
}

:local .fullWidth {
  width: 100%;
}

:local .isDangerousActionPrimary {
  color: #fff;
  fill: #fff;
  background-color: #c00;
}

:local .isDangerousActionPrimary:hover:not(.disabled) {
  color: #fff;
  fill: #fff;
  background-color: #c00;
}

:local .isDangerousActionSecondary {
  color: #c00;
  fill: #c00;
  background-color: #fff;
  border-color: #c00;
}

:local .isDangerousActionSecondary:hover:not(.disabled) {
  color: #c00;
  fill: #c00;
  background-color: #ddd;
  border-color: #c00;
}

:local .isDangerousActionTransparent {
  color: #c00;
  fill: #c00;
  background-color: #0000;
  border-color: #c00;
}

:local .isDangerousActionTransparent:hover:not(.disabled) {
  color: #c00;
  fill: #c00;
  background-color: #ddd;
  border-color: #c00;
}
