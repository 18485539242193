.byline {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #888;
  align-items: flex-end;
  min-height: 18px;
  padding-bottom: 5px;
  display: flex;
  overflow: hidden;
}

.byline.imageXLarge {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.internalByline {
  opacity: .9;
  color: #222;
  background: #f3f3f3;
  justify-content: space-between;
  width: 80%;
  padding: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  display: flex;
  position: absolute;
  top: 16px;
}

.internalByline.isSbTile {
  z-index: 10;
  margin: 0 36px 0 18px;
  top: 18px;
}

.titleWrapper {
  min-height: 41px;
}

.titleWrapper.hasByLine {
  min-height: 59px;
}

.titleWrapper.titleOneLine {
  min-height: auto;
}

.skusBadge {
  margin-top: 9px;
}

.originalListPrice {
  color: #000;
}

.urgencySignalMt {
  margin-top: -4px;
}
