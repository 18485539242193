.swipeIndicator {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 9px;
  color: #fff;
  background-color: rgba(34, 34, 34, 0.6);
  display: flex;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  transition: 0.1s opacity ease, 0.1s visibility ease;
  opacity: 0;
  visibility: hidden;
}
.swipeIndicator.isVisible {
  opacity: 1;
  visibility: visible;
}

.swipeIndicatorArrow {
  height: 16px;
  margin-left: 9px;
}