@keyframes imageGridBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.link {
  display: block;
  position: relative;
}

.product {
  cursor: pointer;
}

.hasDivider {
  border-bottom: solid 1px #ddd;
}

.hasLightBox {
  background: #fff;
  border-color: transparent;
  margin: -27px -18px 0;
}
@media (max-width: 768px) {
  .hasLightBox {
    margin: 0;
    padding: 18px;
  }
}

.imageWrapper {
  padding: 18px;
  padding-bottom: 9px;
  position: relative;
}

.lightBoxImage {
  padding: 27px 18px;
}

.hideImagePadding {
  padding: 0;
}

.hideImageSidePadding {
  padding-left: 0;
  padding-right: 0;
}

.imageContainer, .swiperImageContainer {
  position: relative;
  box-sizing: border-box;
}
.imageContainer.imageXSmall, .imageXSmall.swiperImageContainer {
  max-width: 148px;
  height: 148px;
}
@media (max-width: 1024px) {
  .imageContainer.imageXSmall, .imageXSmall.swiperImageContainer {
    max-width: 90px;
    height: 90px;
  }
}
@media (max-width: 768px) {
  .imageContainer.imageXSmall, .imageXSmall.swiperImageContainer {
    max-width: 90px;
    height: 90px;
  }
}
.imageContainer.imageSmall, .imageSmall.swiperImageContainer {
  max-width: 160px;
  height: 160px;
}
@media (max-width: 1024px) {
  .imageContainer.imageSmall, .imageSmall.swiperImageContainer {
    max-width: 130px;
    height: 130px;
  }
}
@media (max-width: 768px) {
  .imageContainer.imageSmall, .imageSmall.swiperImageContainer {
    max-width: 122px;
    height: 122px;
  }
}
.imageContainer.imageMedium, .imageMedium.swiperImageContainer {
  max-width: 220px;
  height: 220px;
}
@media (max-width: 1024px) {
  .imageContainer.imageMedium, .imageMedium.swiperImageContainer {
    max-width: 180px;
    height: 180px;
  }
}
@media (max-width: 768px) {
  .imageContainer.imageMedium, .imageMedium.swiperImageContainer {
    max-width: 140px;
    height: 140px;
  }
}
@media (max-width: 568px) {
  .imageContainer.imageMedium, .imageMedium.swiperImageContainer {
    max-width: 130px;
    height: 130px;
  }
}
.imageContainer.imageLarge, .imageLarge.swiperImageContainer {
  max-width: 360px;
  height: 360px;
}
@media (max-width: 1024px) {
  .imageContainer.imageLarge, .imageLarge.swiperImageContainer {
    max-width: 180px;
    height: 180px;
  }
}
@media (max-width: 768px) {
  .imageContainer.imageLarge, .imageLarge.swiperImageContainer {
    max-width: 180px;
    height: 180px;
  }
}
.imageContainer.imageXLarge, .imageXLarge.swiperImageContainer {
  max-width: 550px;
  height: 550px;
}
@media (max-width: 1024px) {
  .imageContainer.imageXLarge, .imageXLarge.swiperImageContainer {
    max-width: 450px;
    height: 450px;
  }
}
@media (max-width: 768px) {
  .imageContainer.imageXLarge, .imageXLarge.swiperImageContainer {
    max-width: 350px;
    height: 350px;
  }
}

.centerImage {
  margin: auto;
}

.centerVerticaly {
  margin: auto 0;
}

.swiperImageContainer {
  user-select: none;
}

.image, .swiperImage {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.swiperImage {
  margin: auto;
  padding: 0 1px;
}

.swiperLink {
  width: 100%;
  height: 100%;
}

.carouselWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  visibility: hidden;
}
.arrows.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}

.nextArrow {
  right: 0;
}

.prevArrow {
  left: 0;
}

.productDetailWrapper {
  display: flex;
  padding-bottom: 9px;
  margin-top: -9px;
}
.hasLightBox .productDetailWrapper {
  padding-left: 9px;
}
.productDetailWrapper.hideDivider {
  padding: 0;
}
@media (max-width: 568px) {
  .productDetailWrapper {
    position: relative;
    height: 54px;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media (min-width: 569px) {
  .productDetailWrapper {
    align-items: center;
  }
}

.dimensions {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  color: #888;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
@media (max-width: 568px) {
  .dimensions {
    margin-top: 6px;
    width: 90%;
    line-height: 1.5;
  }
}