.carouselItem {
  scroll-snap-stop: always;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
}

.carouselItem.noneHorizontal {
  padding: 0 .5px;
}

.carouselItem.noneVertical {
  padding: .5px 0;
}

.carouselItem.xSmallHorizontal {
  padding: 0 4.5px;
}

.carouselItem.xSmallVertical {
  padding: 4.5px 0;
}

.carouselItem.smallHorizontal {
  padding: 0 9px;
}

.carouselItem.smallVertical {
  padding: 9px 0;
}

.carouselItem.mediumHorizontal {
  padding: 0 13.5px;
}

.carouselItem.mediumVertical {
  padding: 13.5px 0;
}

.carouselItem.largeHorizontal {
  padding: 0 18px;
}

.carouselItem.largeVertical {
  padding: 18px 0;
}

.carouselSnapItemStart {
  scroll-snap-align: start;
}

.carouselSnapItemEnd {
  scroll-snap-align: end;
}
