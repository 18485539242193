.wrapper {
  font-size: 18px;
  font-weight: 300;
  min-height: 27px;
}
.wrapper.smallText {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  min-height: 0;
}
.wrapper.largeHeavy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 600;
}

.hold {
  color: #222;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}

.showCompactedPrice {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #222;
}

.sold,
.unavailable {
  color: #950808;
  text-transform: uppercase;
  display: inline;
}
.sold:not(:only-child):after,
.unavailable:not(:only-child):after {
  content: "";
  display: inline-block;
  height: 12px;
  width: 1px;
  margin: 0 9px;
  background: #ddd;
}

.pur {
  color: #222;
}

.countdown {
  color: #950808;
  margin-bottom: 9px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.icon {
  fill: #950808;
  height: 14px;
}
.icon.smallText {
  height: 12px;
}

.soldUnavailablePriceWrapper {
  display: block;
}