.carouselWrapper {
  flex-grow: 1;
  align-self: stretch;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.carousel {
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.carousel.noneHorizontal {
  margin: 0 -.5px;
}

.carousel.noneVertical {
  margin: -.5px 0;
}

.carousel.xSmallHorizontal {
  margin: 0 -4.5px;
}

.carousel.xSmallVertical {
  margin: -4.5px 0;
}

.carousel.smallHorizontal {
  margin: 0 -9px;
}

.carousel.smallVertical {
  margin: -9px 0;
}

.carousel.mediumHorizontal {
  margin: 0 -13.5px;
}

.carousel.mediumVertical {
  margin: -13.5px 0;
}

.carousel.largeHorizontal {
  margin: 0 -18px;
}

.carousel.largeVertical {
  margin: -18px 0;
}

.carousel.noneHorizontalScrollPadding, .carousel.noneVerticalScrollPadding {
  padding: 0;
  scroll-padding: 0;
}

.carousel.smallHorizontalScrollPadding {
  padding: 0 18px;
  scroll-padding: 18px;
}

.carousel.smallVerticalScrollPadding {
  padding: 18px 0;
  scroll-padding: 18px;
}

.carousel.largeHorizontalScrollPadding {
  padding: 0 36px;
  scroll-padding: 36px;
}

.carousel.largeVerticalScrollPadding {
  padding: 36px 0;
  scroll-padding: 36px;
}

.carousel.slide {
  overflow: auto;
}

.carousel.none, .carousel.disableScroll {
  overflow: hidden;
}

.carousel.horizontal {
  scroll-snap-type: x mandatory;
  flex-direction: row;
}

.carousel.vertical {
  scroll-snap-type: y mandatory;
  flex-direction: column;
}

.carousel::-webkit-scrollbar {
  display: none;
}
