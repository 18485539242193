.arrowLink {
  color: #666;
  flex-shrink: 0;
  padding: 9px;
  font-size: 0;
}

.arrow {
  width: 26px;
  height: 26px;
}

.arrowFiller {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
}
