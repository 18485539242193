.byline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 18px;
  color: #888;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}
.byline.imageXLarge {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.internalByline {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  top: 16px;
  width: 80%;
  padding: 9px;
  opacity: 0.9;
  color: #222;
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
}
.internalByline.isSbTile {
  z-index: 10;
  top: 18px;
  margin: 0 36px 0 18px;
}

.titleWrapper {
  min-height: 41px;
}
.titleWrapper.hasByLine {
  min-height: calc(41px + 18px);
}
.titleWrapper.titleOneLine {
  min-height: auto;
}

.skusBadge {
  margin-top: 9px;
}

.originalListPrice {
  color: #000;
}

.urgencySignalMt {
  margin-top: -4px;
}